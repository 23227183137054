var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a, _b;
import React from 'react';
import { auxilioModule } from '@cognitranlimited/itis-web/dist/auxilio';
import { checkSheetsModule } from '@cognitranlimited/itis-web/dist/check-sheets';
import { vehicleParkModule } from '@cognitranlimited/itis-web/dist/vehicle-park';
import { joinPath } from './paths';
import { campaignsModule } from '@cognitranlimited/itis-web/dist/campaigns';
import { SidebarLogo } from './SidebarLogo';
import { MobileSidebarLogo } from './MobileSidebarLogo';
import { vehicleInformationModule } from "./vehicleInformationModule/vehicleInformationModule";
function requireGlobalProperty(key) {
    var property = global.iwaProperties[key];
    if (!property && property !== false) {
        throw new Error("Global property not set: ".concat(key));
    }
    return property;
}
export function getStringsFileLanguage(language) {
    return language.replace(/-/, '');
}
export var props = {
    apiUrl: requireGlobalProperty('apiUrl'),
    itisUrl: requireGlobalProperty('itisUrl'),
    authClientId: requireGlobalProperty('authClientId'),
    authSecret: requireGlobalProperty('authSecret'),
    documentAttachmentsS3BucketUrl: requireGlobalProperty('documentAttachmentsS3BucketUrl'),
    brand: 'iwaCore',
    vehiclePlateRecognitionEndpoint: joinPath((_a = requireGlobalProperty('vprUrl')) !== null && _a !== void 0 ? _a : '', 'recognize'),
    stringsLoader: function (languageCode) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, import(/* webpackChunkName: '[request]' */ "./strings/".concat(languageCode, ".strings"))];
                case 1: return [2 /*return*/, (_a.sent()).default];
            }
        });
    }); },
    helpStringsLoader: function (languageCode) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, import(/* webpackChunkName: '[request]' */ "./strings/".concat(languageCode, "Help.strings"))];
                case 1: return [2 /*return*/, (_a.sent()).default];
            }
        });
    }); },
    vehiclePanelOrder: ['notices', 'importantDocuments', 'favouriteContent'],
    hideModelSelectionByDefault: true,
    sidebarLogo: function () { return React.createElement(SidebarLogo, null); },
    mobileSidebarLogo: function () { return React.createElement(MobileSidebarLogo, null); },
    modules: [
        checkSheetsModule(),
        vehicleParkModule({
            vehicleParkApiUrl: requireGlobalProperty('vehicleParkApi')
        }),
        campaignsModule({
            overrides: {
                strings: function (language) {
                    return __awaiter(this, void 0, void 0, function () {
                        var _a;
                        return __generator(this, function (_b) {
                            switch (_b.label) {
                                case 0:
                                    _b.trys.push([0, 2, , 3]);
                                    return [4 /*yield*/, import("./strings/campaigns/".concat(getStringsFileLanguage(language), ".strings"))];
                                case 1: return [2 /*return*/, (_b.sent()).default];
                                case 2:
                                    _a = _b.sent();
                                    return [2 /*return*/, null];
                                case 3: return [2 /*return*/];
                            }
                        });
                    });
                }
            }
        }),
        requireGlobalProperty('apiUrl') !== 'https://technsight.eu.nissan.biz/api' ?
            auxilioModule({
                auxilioAppUrl: requireGlobalProperty('auxilioUrl')
            }) : undefined,
        vehicleInformationModule()
    ].filter(function (module) { return typeof module !== 'undefined'; }),
    showNonVehicleSidebarDocumentGroups: true,
    showVehicleMetadata: true,
    documentViewEventUrl: requireGlobalProperty('documentEventUrl'),
    hideDocumentHeaderFeedbackButton: (_b = requireGlobalProperty('hideDocumentHeaderFeedbackButton')) !== null && _b !== void 0 ? _b : false,
    includePrintHeader: requireGlobalProperty('includePrintHeader')
};
